<template>
  <div>
    <mds-search-field
      :id="id"
      :placeholder="placeholder"
      v-model="searchField"
      @keyup.enter.native="routeToAdvancedSearch"
    ></mds-search-field>
    <mds-search-results
      class="search-results"
      :class="{ 'search-results--small': size === 'small' }"
      v-if="isResultsShown"
      v-click-outside="hideResults"
    >
      <mds-button
        v-if="onSelectRoute"
        class="float-right"
        variation="flat"
        size="small"
        text="Create New Term"
        @click="routeToCreateNewTerm"
      >
      </mds-button>
      <mds-loader v-if="isSearching" size="small"></mds-loader>
      <mds-search-results-section v-else title="">
        <mds-empty-state
          v-if="results && results.length == 0"
          title="No Results"
          message="Try refining your search string."
        ></mds-empty-state>
        <mds-list-group v-else>
          <AppTermSummary
            v-for="(item, index) in results"
            :key="index"
            :termObject="item"
            @click="selectResult(item)"
          />
        </mds-list-group>
      </mds-search-results-section>
    </mds-search-results>
  </div>
</template>

<script>
import AppTermSummary from '@/components/Taxonomy/elements/AppTermSummary.vue';
import { MdsButton } from '@mds/button';
import MdsEmptyState from '@mds/empty-state';
import { MdsListGroup } from '@mds/list-group';
import MdsLoader from '@mds/loader';
import MdsSearchField from '@mds/search-field';
import { MdsSearchResults, MdsSearchResultsSection } from '@mds/search-results';
import Constants from '@/js/constants.js';
import Utils from '@/js/utils.js';
import { uuid } from 'vue-uuid';
import '@/js/directive.js';

export default {
  name: 'TermSearchField',
  components: {
    AppTermSummary,
    MdsButton,
    MdsEmptyState,
    MdsListGroup,
    MdsLoader,
    MdsSearchField,
    MdsSearchResults,
    MdsSearchResultsSection,
  },
  mixins: [Constants, Utils],
  props: {
    onSelectRoute: {
      type: Boolean,
      default: true,
    },
    labelFilter: {
      type: Set,
      description: 'If not null, filter the results to only some classes',
    },
    placeholder: String,
    size: String,
  },
  mounted() {
    let this_ = this;
    document.getElementById(this.id).addEventListener('click', function () {
      this_.$store.dispatch('getSynonym');
    });
  },
  data() {
    return {
      id: uuid.v1(),
      results: [],
      debounce: null,
      searchField: '',
      selectedResult: null,
      isResultsShown: false,
      searchingCount: 0,
      n_letter_start_search: 3,
      classFilter: '',
    };
  },
  methods: {
    async debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.advancedSearch();
      }, 500);
    },
    async advancedSearch() {
      this.searchingCount++;
      let query = {
        'name.like': this.searchField,
        start: 0,
        end: 20,
      };
      if (!this.searchField) query[this.PPT_LABEL] = '';
      else {
        this.showResults();
        if (this.classFilter) {
          query[this.PPT_LABEL] = this.classFilter;
        } else if (this.labelFilter) {
          query[this.PPT_LABEL] = Array.from(this.labelFilter).join(',');
        }
      }

      await this.$store.dispatch('advancedSearch', query);
      this.results = this.$store.state.term.searchedResults;
      this.searchingCount--;
    },
    async selectClass(selectedClass) {
      this.classFilter = selectedClass;
      await this.advancedSearch();
    },
    routeToAdvancedSearch() {
      if (this.onSelectRoute) {
        let query = {};
        if (this.searchField) {
          query = {
            'name.like': this.searchField,
            start: 0,
            end: 20,
          };
          if (this.classFilter) {
            query['label'] = this.classFilter;
          }
        }
        this.routeToPage('AdvancedSearch', query);
      }
    },
    routeToCreateNewTerm() {
      this.routeToPage('TermCreate');
    },
    showResults() {
      this.isResultsShown = true;
    },
    hideResults() {
      this.isResultsShown = false;
      this.$store.dispatch('clearSearchResult');
      this.results = [];
    },
    selectResult(item) {
      this.searchField = item['name'];
      this.selectedResult = this.searchField;
      this.hideResults();
      if (this.onSelectRoute) {
        this.searchField = item.name;
        this.routeToPage('TermView', {}, { entryId: item.id });
      } else {
        this.searchField = '';
        this.$emit('selected', item);
      }
    },
  },
  computed: {
    isSearching() {
      return this.searchingCount > 0;
    },
    filterOptions() {
      if (this.labelFilter) {
        let result = [];
        this.labelFilter.forEach(element => {
          result.push({
            text: this.decamelize(element),
            value: element,
          });
        });
        return result;
      } else {
        return this.$store.state.schema.classNameList;
      }
    },
  },
  watch: {
    searchField(newValue) {
      if (newValue.length < this.n_letter_start_search) {
        this.hideResults();
      } else {
        if (this.selectedResult !== newValue) {
          this.debounceSearch();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.search-results {
  &.search-results--small {
    width: 190px;
  }
  width: 360px;
  max-height: 360px;
  margin-top: $mds-space-half-x;
  z-index: 99;
}
</style>
